.home-page-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .home-page-container header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .home-page-container h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .home-page-container .subtitle {
    font-size: 1.2rem;
    color: #666;
  }
  
  .home-page-container .main {
    display: flex;
    flex-direction: column;
  }
  
  .home-page-container .section {
    margin-bottom: 30px;
  }
  
  .home-page-container .section h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  
  .home-page-container .section p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .home-page-container .section ul {
    margin-bottom: 10px;
    padding-left: 20px;
  }
  
  .home-page-container .section ul li {
    font-size: 1.1rem;
    margin-bottom: 5px;
  }
  
  .home-page-container .learn-more {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
  }
  
  .home-page-container .contact-form {
    color: #007bff;
  }
  
  .home-page-container footer {
    text-align: center;
    margin-top: 50px;
  }
  
  .home-page-container footer p {
    font-size: 1rem;
    color: #666;
  }
  