.app-container {
  padding-top: 5vh;
}

.editor-container {
  margin-left: 20vw;
  margin-top: 10vh;
  width: 60vw;
}

.footerLinks a:visited { color: rgb(62, 135, 156) }

.footerLinks a { color: rgb(62, 135, 156)}

.info-section {
  margin-top: 20vh;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.footer {
  color: gray;
  margin-top: 20vh;
  text-align: center;
}

.footer ul {
  list-style-type: none;
  padding: 0;
}

.footer-link {
  color: gray;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}


.home-page-container .home-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.home-page-container header {
  text-align: center;
  margin-bottom: 20px;
}

.home-page-container h1 {
  font-size: 2.5em;
}

.home-page-container p {
  font-size: 1.1em;
}

.home-page-container main {
  display: flex;
  flex-direction: column;
}

.home-page-container .tools,
.home-page-container .about,
.home-page-container .contact {
  margin-bottom: 40px;
}

.home-page-container .tools ul {
  list-style: none;
  padding: 0;
}

.home-page-container .tools ul li {
  margin-bottom: 10px;
}

.home-page-container .about p,
.home-page-container .contact p {
  margin-bottom: 10px;
}

.home-page-container footer {
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

